import { createAction, props } from '@ngrx/store';

export namespace FeatureFlagActions {
  export const loadFeatureFlags = createAction(
    '[FEATURE FLAG] LOAD_FEATURE_FLAGS',
  );

  export const loadShowMessageSuccess = createAction(
    '[FEATURE FLAG] LOAD_SHOW_INFO_MESSAGE_SUCCESS',
    props<{ payload: boolean }>(),
  );
  export const loadShowMessageError = createAction(
    '[FEATURE FLAG] LOAD_SHOW_INFO_MESSAGE_ERROR',
  );

  export const loadJobPlannerV2FeatureSuccess = createAction(
    '[FEATURE FLAG] LOAD_JOB_PLANNER_V2_SUCCESS',
    props<{ payload: boolean }>(),
  );

  export const loadJobPlannerV2FeatureError = createAction(
    '[FEATURE FLAG] LOAD_JOB_PLANNER_V2_ERROR',
  );

  export const loadIsLoadChartEnabledSuccess = createAction(
    '[FEATURE FLAG] LOAD_IS_LOAD_CHART_ENABLED_SUCCESS',
    props<{ payload: boolean }>(),
  );

  export const loadIsLoadChartEnabledError = createAction(
    '[FEATURE FLAG] LOAD_IS_LOAD_CHART_ENABLED_ERROR',
  );
}
