import { FeatureFlagState, featureFlagKey } from './feature-flag.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const selectFeatureFlags =
  createFeatureSelector<FeatureFlagState>(featureFlagKey);

export namespace FeatureFlagSelectors {
  export const isInfoMessageShownEnabled = createSelector(
    selectFeatureFlags,
    (state) => state.isInfoMessageShown,
  );

  export const isJobPlannerV2Enabled = createSelector(
    selectFeatureFlags,
    (state) => state.isJobPlannerV2Enabled,
  );

  export const isLoadChartEnabled = createSelector(
    selectFeatureFlags,
    (state) => state.isLoadChartEnabled,
  );
}
